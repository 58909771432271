import React from 'react';
import styled, { css } from 'styled-components';
import { useSelect } from 'downshift';

import Arrow from '@/assets/svg/arrow-down.svg';
import Lock from '@/assets/svg/lock.svg';
import { colors } from '@/constants/theme';
import { ScrollStyles } from '@/components/styles';
import CheckIcon from '@/assets/svg/checkbox-tick.svg';
import CrossIcon from '@/assets/svg/close-12.svg';

import { StockFilterSelectProps } from './StockFilterSelect.types';

function StockFilterSelect({
  label,
  value,
  options,
  onChange,
  onClear,
  disabled,
}: StockFilterSelectProps) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem?.value === value.value) {
        return;
      }

      onChange?.(selectedItem ?? options[0]);
    },
  });

  const handleClear = (event: React.SyntheticEvent) => {
    event.stopPropagation();
    onClear?.();
  };

  return (
    <Component>
      {onClear && selectedItem?.value && (
        <ClearButton onClick={handleClear}>
          <CrossIcon />
        </ClearButton>
      )}
      <Select type="button" disabled={disabled} {...getToggleButtonProps()}>
        <Label isSelectedItem={!!value.label}>
          {selectedItem?.label || label}
        </Label>

        <SvgWrapper isOpen={isOpen} disabled={disabled}>
          {disabled ? <Lock /> : <Arrow />}
        </SvgWrapper>
      </Select>
      <Content isOpen={isOpen} {...getMenuProps()}>
        {options.map((item, index) => {
          const isActive = item.value === selectedItem?.value;
          return (
            <ListItem
              key={index}
              isActive={isActive}
              {...getItemProps({
                item: item,
                index,
              })}
            >
              {item.label}
              {isActive && <CheckIcon />}
            </ListItem>
          );
        })}
      </Content>
    </Component>
  );
}

export default StockFilterSelect;

const Component = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.white_1};
  border: 1px solid ${(props) => props.theme.gray1000};
  border-radius: 6px;
`;

const Select = styled.button`
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 15px 10px;
  height: 38px;

  &:disabled {
    cursor: not-allowed;
  }
`;

const Label = styled.div<{ isSelectedItem: boolean }>`
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: ${(props) => props.theme.blue100};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ isSelectedItem }) =>
    isSelectedItem &&
    css`
      color: ${(props) => props.theme.black200};
    `}
`;

const ClearButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  svg {
    width: 9px;
    height: 9px;

    path {
      fill: ${(props) => props.theme.main};
    }
  }
`;

const SvgWrapper = styled.div<{ isOpen: boolean; disabled?: boolean }>`
  svg {
    transform: rotate(0deg);
    transition: all 0.3s ease-in-out;

    path {
      fill: ${(props) => props.theme.black200};
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      svg {
        transform: rotate(180deg);

        path {
          fill: ${(props) => props.theme.main};
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      svg {
        path {
          fill: ${(props) => props.theme.main};
        }
      }
    `}
`;

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 5;
  pointer-events: none;
  position: absolute;
  top: 40px;
  width: 100%;
  max-height: 0;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
  background: ${(props) => props.theme.white_1};
  opacity: 0;
  border: 1px solid ${(props) => props.theme.gray1000};
  box-shadow: ${(props) => props.theme.blackShadow100};
  ${ScrollStyles};

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      max-height: 280px;
      transition: all 0.3s ease-in-out;
    `}
`;

const ListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  padding: 10px 15px;
  font-size: 16px;
  line-height: 19px;
  transition: all 0.3s ease-in-out;
  color: ${(props) => props.theme.blue100};

  &:hover {
    background: ${(props) => props.theme.gray300};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 600;
      color: ${(props) => props.theme.black200};

      svg {
        flex: 0 0 23px;
        height: 19px;

        path {
          fill: ${colors.main};
          stroke: ${colors.main};
        }
      }
    `}
`;
